<template>
    <b-container fluid>
        <b-row>
            <b-col>
                <b-button-toolbar class="mb-3">
                    <b-button-group class="ml-auto">
                        <Button to="add">
                            <b-icon icon="plus" />
                            Добавить
                        </Button>
                    </b-button-group>
                </b-button-toolbar>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table
                    ref="table"
                    striped
                    hover
                    :items="itemsProvider"
                    :fields="fields"
                    :busy.sync="isBusy"
                >
                    <template #cell(actions)="row">
                        <b-button-toolbar>
                            <b-button-group class="ml-auto">
                                <Button
                                    v-if="$auth.check('admin')"
                                    :to="{path:`${row.item.id}/edit`}"
                                >
                                    <b-icon icon="pencil" />
                                </Button>
                                <poster
                                    v-if="$auth.check('admin')"
                                    tag="b-button"
                                    :item-id="row.item.id"
                                    method="admin.smsTemplate.delete"
                                    success-message="Данные удалены"
                                    @complete="refreshTable"
                                >
                                    <b-icon icon="trash" />
                                </poster>
                            </b-button-group>
                        </b-button-toolbar>
                    </template>
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle" />
                            <strong>Загрузка данных...</strong>
                        </div>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    data() {
        return {
            fields: [
                {
                    key: "id",
                    label: "Id",
                },
                {
                    key: "name",
                    label: "Название",
                },
                {
                    key: "value",
                    label: "Предпросмотр",
                },
                {
                    key: "updatedAt",
                    label: "Изменен",
                    formatter(value) {
                        return window.formatDatetime(value)
                    },
                },
                {
                    key: "actions",
                    label: "",
                },
            ],
            isBusy: false,
        };
    },
    methods: {
        itemsProvider() {
            this.isBusy = true;

            return this
                .jsonRpc("admin.smsTemplate.fullList")
                .finally(async () => {
                    this.isBusy = false;
                });
        },
        refreshTable() {
            this.$refs.table.refresh();
        },
    },
}
</script>
